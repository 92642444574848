$(
  (function ($) {
    const selectors = {
      mockForm: ".mock-form",
      mainForm: ".main-form",
      form: ".b2cform",
      step1: ".b2cform .b2c-step-first",
      leadType: "#leadtypeid",
      mockFormBtn: ".mock-form label",
      mockFormContinueBtn: ".mock-form .mock-b2c-btn",
      mockFormSelect: '.mock-form select[name="RequestedAmount"]',
      mainFormSelect: '.main-form select[name="RequestedAmount"]',
      initButtons: ".b2c-btn,.b2c-btn-verify,.b2c-btn-submit"
    };
    const classes = {
      loading: "loading"
    };
    const opt = {
      requestedAmount: ""
    };
    const patterns = {
      minValue: /\d+/i
    };

    function toggleLoader(state) {
      $(selectors.mockForm).toggleClass(classes.loading, state);
    }

    function toggleMockForm(state) {
      $(selectors.mockForm).toggle(state);
    }

    function setLeadType(value) {
      $(selectors.form)
        .find(selectors.leadType)
        .val(value);
    }

    function setLoanAmount() {
      if (opt.requestedAmount !== "" && $(selectors.step1).length) {
        const leadType = Number(opt.requestedAmount) > 1000 ? 19 : 9,
          minAmount = $(selectors.step1)
            .find("input[value=" + opt.requestedAmount + "]")
            .attr("data-min-value");

        $('input[name="MinLoanAmount"]').val(minAmount);
        setLeadType(leadType);
      }
    }

    function setSelectAmount() {
      const value = $(selectors.mockFormSelect).val(),
        leadType = Number(value) > 1000 ? 19 : 9;

      $(selectors.mainFormSelect).val(value);
      setLeadType(leadType);
    }

    function setRequestedAmount() {
      if (opt.requestedAmount !== "") {
        const input = $(selectors.step1).find(
          "input[value=" + opt.requestedAmount + "]"
        );
        input.attr("checked", true);

        setLoanAmount();
      }
    }

    function showMainForm() {
      const timerLabel = setInterval(function () {
        if ($(selectors.initButtons).length) {
          clearInterval(timerLabel);

          toggleLoader(false);
          toggleMockForm(false);
          if ($(selectors.mockFormSelect).length) {
            setSelectAmount();
          } else {
            setRequestedAmount();
          }
          $(selectors.mainForm).show();
        }
      }, 100);
    }

    function setEventHandler() {
      if ($(selectors.mockFormBtn).length) {
        $(selectors.mockFormBtn).on("click", function () {
          toggleLoader(true);
          opt.requestedAmount = $(this)
            .find("[name=RequestedAmount]")
            .val();
          showMainForm();
        });
      }

      if ($(selectors.mockFormSelect).length) {
        $(selectors.mockFormSelect).on("change", function () {
          toggleLoader(true);
          showMainForm();
        });

        $(selectors.mockFormContinueBtn).on("click", function () {
          toggleLoader(true);
          showMainForm();
        });
      }
    }

    (function init() {
      setEventHandler();
      showMainForm();
    })();
  })(window.jQuery)
);
